body.show-menu {
    overflow-y: hidden;
}
.main-header {
    padding: 15px 0;
    position: fixed;
    top: 0;
	width: 100%;
	z-index: 9;
	transition: all .2s;
	.logo {
		width: 170px;
		display: block;
		position: relative;
		max-width: 100%;
		padding: 2px 0 0;
		line-height: 1;
		img {
			position:absolute;
			left:0;
			transition: all .2s;
		}
	}
	.nav-toggle {
		border: none;
		position: relative;
		float: right;
		margin-right: 15px;
		padding: 4px 10px;
		margin-top: 18px;
		margin-bottom: 18px;
		background-color: transparent;
		background-image: none;
		&:focus {
			outline: none;
		}
		.icon-bar {
			display: block;
			width: 24px;
			height: 3px;
			border-bottom: 2px solid #fff;
			margin-top: 5px;
		}
	}
	.nav {
		list-style: none;
		margin: 0;
		li {
			display: inline-block;
			margin: 0;
			position: relative;
			a {
				display: block;
				padding: 26px 16px;
				text-align: center;
				font-size: 17px;
				color:#fff;
				transition: all .2s;
				&:hover {
					color: $brand-secondary;
				}
				@include media("<desktop") {
					font-size: 16px;
					padding: 26px 10px;
				}
			}
			&.fb {
				a {
					padding: 0;
					svg {
						fill: currentColor;
					}
				}
			}
			ul.dropdown-menu {
				position: absolute;
				top: 100%;
				left: 0;
				z-index: 1000;
				display: none;
				float: left;
				min-width: 240px; //160px;
				padding: 0;
				margin: 0;
				list-style: none;
				font-size: 14px;
				text-align: left;
				background-color: #fff;
				border-radius: 4px;
				box-shadow: 0 16px 12px rgba(0, 0, 0, 0.175);
				background-clip: padding-box;
				li {
					width: 100%;
					a {
						text-align: left;
						padding: 8px 16px;
						color: #424242;
						&:hover {
							color: $brand-secondary;
						}
					}
					&.dropdown-parent-mob {
						display: none;
					}
				}
			}
		}
		@include media(">desktop") {
			li {
				&:hover {
					ul.dropdown-menu {
						display: block;
					}
				}
			}
		}
		@include media("<desktop") {
			position: fixed;
			z-index: 1000;
			height: 100vh;
			padding: 15vh 0;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			width: 100vw;
			right: -100vw;
			top: 0;
			bottom: 0;
			opacity: 0;
			background-color: #fff;
			transition: opacity .1s linear,right 0s linear .2s;
			.nav-close {
				height: 56px;
				width: 56px;
				position: absolute;
				top: 16px;
				right: 20px;
				border-radius: 100%;
				cursor: pointer;
				z-index: 1;
				border: none;
				&:before,&:after {
					content: "";
					height: 21px;
					width: 2px;
					background-color: #0f0f0f;
					display: block;
					position: absolute;
					left: 26px;
					top: 16px;
				}
				&:before {
					transform: rotate(-45deg);
				}
				&:after {
					transform: rotate(45deg);
				}
			}
			.item {
				display: block;
				width: 100%;
				a {
					color: #000;
					font-size: 26px;
					.dropdown-icon {
						display: inline-block;
						width: 12px;
						height: 12px;
						border-right: 2px solid #0f0f0f;
						border-bottom: 2px solid #0f0f0f;
						transform: translateY(-3px) rotate(45deg);
						margin-left: 12px;
						margin-right: -12px;
					}
				}
				ul.dropdown-menu {
					display: none;
					position: relative;
					width: 100%;
					background: #ebf5fb;
					padding: 20px 0;
					box-shadow: none;
					margin: 0px 0 20px 0;
					li {
						a {
							text-align: center;
							padding: 18px 0;
						}
						&.dropdown-parent-mob {
							display: block;
						}
					}
					&.show {
						display: block;
					}
				}
			}
			&.show {
				right: 0;
				opacity: 1;
			}
		}
	}
	&.fixed {
		background: #fff;
		box-shadow: 0 1px 3px rgba(0,0,0,.05), 0 1px 2px rgba(0,0,0,.12);
		.nav {
			li {
				a {
					color: $brand-primary;
					&:hover {
						color: #144861;
					}
				}
				ul.dropdown-menu {
					li {
						a {
							color: $brand-primary;
							&:hover {
								color: #144861;
							}
						}
					}
				}
			}
		}

		@include media("<desktop") {
			.nav-toggle {
				.icon-bar {
					border-bottom: 2px solid #000;
				}
			}
			.nav {
				.item {
					a {
						color: #000;
					}
				}
			}
		}
		.logo {
			.white {
				opacity: 0;
			}
		}
	}
}
@media only screen and (max-width: 1200px) {
	.main-header {
		.nav {
			li {
				a {
					font-size: 15px;
					padding: 26px 10px;
				}
			}
		}
	}	
}

@media only screen and (max-width: 64em) {
	.container-header {
		width: 100%;
	}
}
