body.home {
	background: $brand-primary; //For scrolling up on phones/ipads
	.hero-image {
		position: absolute;
		top: 0;
		left: 0;
		max-height: 700px;
		height: 100vh;
		width: 100%;
		min-height: 570px;
		background: $brand-primary; //For slow connections
		transition: height .2s;
		img {
			height: 0;
			object-fit: cover;
			width: 100%;
			padding-bottom: 56.25%;
			&.lazyloaded {
				height: 100%;
				padding: 0;
			}
		}
		.hero-overlay {
			position: absolute;
			opacity: 0.55;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: #048ccc; //$brand-primary;
		}
	}
	.hero-container {
		position: relative;
		padding-top: 200px;
		height: 100vh;
		min-height: calc(570px);
		max-height: 700px;
		h1 {
			font-size: 50px;
			line-height: 1.1;
			color: #fff;
			text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.45)
		}
		h4 {
			font-family: $basefont;
			font-weight: $font-weight-light;
			line-height: 1.3;
			color: #fff;
			margin-bottom: 40px;
		}
		.button {
			&:first-of-type {
				margin-left: 0;
			}
		}
		
	}
	.home-container {
		background: $body-bg;
		.home-row {
			padding: 60px 0;
			h2 {
				border-bottom: 4px solid $brand-secondary;
				display: inline-block;
				padding-bottom: 5px;
			}
			.home-image {
				overflow: hidden;
				line-height: 0;
				box-shadow: -1px -1px 15px -2px rgba(0, 0, 0, 0.3);
				border-radius: 8px;
				img {
					height: 0;
					object-fit: cover;
					width: 100%;
					padding-bottom: 56.25%;
					&.lazyloaded {
						height: 100%;
						padding: 0;
					}
				}
			}
			.slider-container {
				width: 100%;
				overflow: visible;display: block;
				.slick-slide {
					margin: 0 15px;
					//max-width: 400px;
				}
				.slick-prev {
					left: -50px;
				}
				.slick-next {
					right: -50px;
				}
				.slick-prev, .slick-next {
					height: 35px;
					width: 35px;
					&:before {
						font-size: 35px;
					}
				}
				.resource-card {
					position: relative;
					overflow: hidden;
					height: 170px;
					width: 100%;
    				margin: 0 7px;
					border-radius: 5px;
					background: #fff;
					box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
					transition: all 0.25s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
					&:hover {
						box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
						//box-shadow: 0px 2px 10px 1px #e4e4e4;
						.image {
							img, .no-js-card-img {
								transform: scale(1.04);
							}
						}
					}
					.image {
						overflow: hidden;
						img {
							max-height: 200px;
							height: 100%;
							object-fit: cover;
							width: 100%;
							transition: all .25s ease-out;
						}
					}
					.card-inner {
						padding: 30px;
						h4 {
							margin-bottom: 5px;
							line-height: 1.3;
							height: 2.6em;
							overflow: hidden;
							a {
								color: #000;
							}
						}
						p {
							cursor: text;
							min-height: calc(1.8em * 3);
						}
						.btn-border {
							margin: 0 0 30px 0;
							float: right;
						}
					}
					.card-link-mob {
						display: none;
						@include media("<tablet") {
							display: block;
							height: 100%;
							width: 100%;
							position: absolute;
							top: 0;
							left: 0;
							opacity: 0;
						}
					}
				}
			}
			
			h1 {
				margin-bottom: 50px;
			}
		}
	}


	.top {
		background: $brand-secondary;
		color: $brand-primary;
		padding: 30px;
		h1, h2 {
			font-family: $headingfont;
		}
	}
	.blocks {
		margin: 30px 0;
		.block-row {
			.row {
				flex: 1;
			}
			margin: 0;
    		padding: 40px 0;
			.block-text {
				padding: 30px 60px;
			}
			.block-img {
				img {
					line-height: 0;
				}
			}
			&:first-child {
				padding-top: 0;
			}
			&:nth-child(even) {
				background: $brand-secondary;
				color: $brand-primary;
			}
		}
	}
	.bottom {
		margin-top: 30px;
		background: $brand-secondary;
		color: $brand-primary;
		padding: 30px;
		h2 {
			text-transform: uppercase;
		}
		img {
			max-width: 200px;
		}
	}
	@include media("<desktop") {
		.home-container {
			.home-row {
				padding: 0 0 60px 0;
				.home-image {
					margin-bottom: 30px;
				}
				&:first-of-type {
					padding-top: 60px;
				}
			}
		}
	}
	@include media("<tablet") {
		.hero-container {
			text-align: center;
		}
		.home-container {
			text-align: center;
			.home-row {
				.slider-container {
					margin-top: 30px;
					overflow: hidden;
				}
			}
		}
	}
}