.resource-card{
	overflow: hidden;
	box-shadow: -1px -1px 15px -2px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	position: relative;
	.card-image {
		line-height: 0;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		transition: all .2s;
		img {
			height: 0;
			object-fit: cover;
			width: 100%;
			padding-bottom: 56.25%;
			&.lazyloaded {
				height: 100%;
				padding: 0;
			}
		}
	}
	.title {
		position: absolute;
		bottom: 0;
		width: 100%;
		text-align: center;
		padding: 10px 0;
		h4 {
			margin: 0;
			text-shadow: 0px 0px 15px #000;
			color: #fff;
		}
	}
	&:hover {
		.card-image {
			transform: scale(1.1);
		}
	}
}
.card {
	margin-bottom: 80px;
	.card-image {
		line-height: 0;
		position: relative;
		overflow: hidden;
		margin: 0 15px 30px 15px;
		box-shadow: -1px -1px 15px -2px rgba(0, 0, 0, 0.3);
		border-radius: 8px;
		transition: all .2s;
		img {
			height: 0;
			object-fit: cover;
			width: 100%;
			padding-bottom: 56.25%;
			transition: transform .3s;
			&.lazyloaded {
				height: 100%;
				padding: 0;
			}
		}
		.play-icon {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			color: #fff;
			z-index: 2;
			svg {
				stroke: #000;
				stroke-width: 1px;
			}
		}
		&:hover {
			img {
				transform: scale(1.05);
			}
		}
	}
	a.card-title {
		display: inline-block;
		margin-bottom: 30px;
		h4 {
			display: inline;
			line-height: 1.2;
			border-bottom: 2px solid rgba(255,255,255,0);
			transition: all .2s;
		}
		&:hover {
			h4 {
				border-bottom: 2px solid rgba($brand-secondary, 0.8);
			}
		}
	}
}
