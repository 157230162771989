/**
 *  TABS
 *
 *  Markup:
 *  -------
 *
 *  <div class="c-tabs">
 *    <a href="link" title="#" data-targetclass="js-tab1" class="c-tab js-tab">[...]</a>
 *    <a href="link" title="#" data-targetclass="js-tab2" class="c-tab js-tab active">[...]</a>
 *  </div>
 *  <div class="js-tab1 c-tab-content">
 *    [...]
 *  </div>
 *  <div class="js-tab2 c-tab-content selected">
 *    [...]
 *  </div>
 *
 */

.tabs {
  border-bottom: 1px solid $border-color;
  margin-bottom: 30px;
  text-align: center;

  .tab {
    border-bottom: 3px solid transparent;
    color: $text-gray;
    display: inline-block;
    line-height: 50px;
    margin: 0;
    margin-right: 30px;
    min-width: 70px;
    position: relative;

    &:hover {
      color: $brand-primary;
      text-decoration: none;
    }

    &.active {
      border-bottom: 3px solid $brand-primary;
      color: $brand-primary;
    }
  }
}

.tab-content {
  display: none;

  &.selected {
    display: block;
  }
}
