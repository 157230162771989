/**
 * MIXINS
 */


// Creates a wrapper for a series of columns
// @include make-row();

@mixin make-row($gutter: $grid-gutter-width) {
  margin-left:  ($gutter / -2);
  margin-right: ($gutter / -2);
  @include clearfix();
}

//  Breakpoints and media queries:
//
//  Usage:
//------------------------------------
//
//  @include breakpoint($breakpoint, $query1) {
//   /* Styles */
//  }
//
//  $breakpoint: select the device type: bp-small, bp-medium, bp-large, bp-huge. Sizes are defined in _variables.scss
//  $query1: select max-width, min-width, max-height, min-height
//
//
//  Example:
// ------------------------------------
//
//  .hello {
//    color: red;
//      @include breakpoint(bp-huge, max-width) {
//      color:blue;
//    }
//  }
//
//
//  Returns:
// ------------------------------------
//
//  .hello { color: red; }
//  @media (max-width: 1200px) { .hello { color:blue; } }
//
//  Enjoy!


@mixin breakpoint($breakpoint, $query: 'min-width') {
  $breakpoints: (
  'bp-small': $screen-xs-min,
  'bp-medium': $screen-sm-min,
  'bp-large': $screen-md-min,
  'bp-huge': $screen-lg-min,
  );

  @if map-has-key($breakpoints, $breakpoint) {
    @media (#{$query}: map-get($breakpoints, $breakpoint)) { @content; }
  } @else {
    @warn 'No breakpoint found for `#{$breakpoint}`. Allowed values are: #{map-keys($breakpoints)}.';
  }
}

// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/

@mixin clearfix {
  &::before,
  &::after {
    content: ''; // 1
    display: table; // 2
  }

  &::after {
    clear: both;
  }
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

// Responsive visibility
// More easily include all the states for Responsive visibility helpers.
// [converter] $parent hack

@mixin responsive-visibility($parent) {
  #{$parent} {
    display: block !important;
  }
  table#{$parent}  { display: table; }
  tr#{$parent}     { display: table-row !important; }
  th#{$parent},
  td#{$parent}     { display: table-cell !important; }
}

// [converter] $parent hack
@mixin responsive-invisibility($parent) {
  #{$parent} {
    display: none !important;
  }
}

// .box-sizing(VALUE); (border-box, padding-box, content-box)
@mixin box-sizing($boxsize: border-box) {
  box-sizing: $boxsize;
}

// .bordered(COLOR, COLOR, COLOR, COLOR);
@mixin bordered($top-color: #eee, $right-color: #eee, $bottom-color: #eee, $left-color: #eee) {
  border-bottom: solid 1px $bottom-color;
  border-left: solid 1px $left-color;
  border-right: solid 1px $right-color;
  border-top: solid 1px $top-color;
}

// .border-radius(VALUE,VALUE,VALUE,VALUE);
@mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
  background-clip: padding-box;
  border-bottom-left-radius: $bottomleft;
  border-bottom-right-radius: $bottomright;
  border-top-left-radius: $topleft;
  border-top-right-radius: $topright;
}

//  Circle color mixin (used in $CIRCLE RATING)
//
//   `@include circle-color($rating1);`
//
//
@mixin circle-color($color) {
  color: $color;

  .circle-fill {
    background-color: $color;
  }
}

//Tim Coysh added below this line
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
