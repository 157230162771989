/**
 *  MESSAGE BARS
 *
 *  Markup:
 *  -------
 *
 *  <div class="message-bar background-sucess">Success message</div>
 *  <div class="message-bar background-info">Success info</div>
 *  <div class="message-bar background-error">Success error</div>
 *  <div class="message-bar background-warning">Success warning</div>
 *
 */

.message-bar {
  border-radius: $radius;
  margin-bottom: 1em;
  padding: 1em;

  p:last-child {
    margin-bottom: 0;
  }
  &.background-success,&.background-warning,&.background-error {
    color: #fff;
  }
}
