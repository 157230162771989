@mixin darken-bg($percentage: 0.25) {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,$percentage);
	}
}

@mixin vertical-align($position: relative) {
	position: $position;
	top: 50%;
	transform: translateY(-50%);
}
