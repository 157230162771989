/**
 * Library
 */

@import 'sierra/utils/mixins';
@import 'sierra/placeholders';
@import 'sierra/vendor/reset';

@import 'sierra/main';

@import 'sierra/background-colors';
//@import 'sierra/badges';
@import 'sierra/buttons';
@import 'sierra/forms';
@import 'sierra/helpers';
@import 'sierra/layout';
//@import 'sierra/loading-bar';
//@import 'sierra/loading-spinner';
@import 'sierra/message-bars';
@import 'sierra/paginator';
//@import 'sierra/rating-circle';
@import 'sierra/tables';
@import 'sierra/tabs';
//@import 'sierra/tags';
@import 'sierra/typography';

// Flexbox based grid. More info: https://github.com/kristoferjoseph/flexboxgrid
@import 'sierra/flexboxgrid';

// Twitter bootstrap's grid. More info: https://getbootstrap.com/examples/grid/
//@import 'sierra/bootstrapgrid';
