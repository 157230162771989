/**
 *  FLEX ALIGNMENTS
 */

// container
.aligner {
  display: flex;
}

.aligner-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.aligner-space-around {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.aligner-center-vertical {
  align-items: center;
  display: flex;
}

.aligner-center-horitzontal {
  display: flex;
  justify-content: center;
}

.content-start {
  display: flex;
  justify-content: flex-start;
}

.content-end {
  display: flex;
  justify-content: flex-end;
}

// item
.aligner-item-top {
  align-self: flex-start;
}

.aligner-item-bottom {
  align-self: flex-end;
}

.flex-grow {
  flex-grow: 1;
}


/**
 *  ALIGNMENTS
 */

.fleft {
  float: left;
}

.fright {
  float: right;
}

.cf {
  @include clearfix();
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}


.align-bottom {
  vertical-align: bottom;
}
.align-middle {
  vertical-align: middle;
}
.align-top {
  vertical-align: top;
}


/**
 *  TEXT UTILITIES
 */

.ellipsis {
  @include ellipsis();
}

.ellipsis-block {
  @include ellipsis();
  display: block;
}

.word-break {
  word-break: break-all;
}

.text-indent {
  padding-left: $gutterwidth;
}


/**
 *  MODIFIERS
 */

.bordered {
  border: 1px solid $border-color;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

.border-left {
  border-left: 1px solid $border-color;
}

.border-right {
  border-right: 1px solid $border-color;
}

.border-top {
  border-top: 1px solid $border-color;
}

.display-block {
  display: block;
}

.block-mobile {
  @include breakpoint(bp-small, max-width) {
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: 100%; // useful for buttons
  }
}

.display-inline {
  display: inline;
}

.hidden {
  display: none;
}

.no-margin {
  margin: 0!important;
}

.no-wrap {
  white-space: nowrap;
}

.overflow-hidden {
  overflow: hidden;
}

.opacity-low {
  opacity: .5;
}


// deprecated ".icon-rounded". Use rounded-corners instead.
.icon-rounded,
.rounded-corners {
  border-radius: 15%;
}

.rounded {
  border-radius: 100%;
}


/**
 *  VISIBILITY UTILITIES
 */

.hidden-large {

  @include breakpoint(bp-large) {
    display: none;
  }

  @include breakpoint(bp-huge) {
    display: inline-block;
  }
}

.hidden-medium {

  @include breakpoint(bp-medium) {
    display: none;
  }

  @include breakpoint(bp-large) {
    display: inline-block;
  }
}

.show-small {
  @include breakpoint(bp-medium) {
    display: none;
  }
}

.hide-small {
  display: none;

  @include breakpoint(bp-medium) {
    display: block;
  }
}

.hide-small-inline-block {
  display: none!important;

  @include breakpoint(bp-medium) {
    display: inline-block!important;
  }
}

.hide-medium {
  display: block;

  @include breakpoint(bp-medium) {
    display: none;
  }

  @include breakpoint(bp-large) {
    display: block;
  }
}

/**
 *  BOOTSTRAP VISIBILITY UTILITIES
 *  Note: Deprecated .visible-xs, .visible-sm, .visible-md, and .visible-lg as of v3.2.0
 */

@include responsive-invisibility('.visible-xs');
@include responsive-invisibility('.visible-sm');
@include responsive-invisibility('.visible-md');
@include responsive-invisibility('.visible-lg');

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: $screen-xs-max) {
  @include responsive-visibility('.visible-xs');
}

.visible-xs-block {
  @media (max-width: $screen-xs-max) {
    display: block !important;
  }
}

.visible-xs-inline {
  @media (max-width: $screen-xs-max) {
    display: inline !important;
  }
}

.visible-xs-inline-block {
  @media (max-width: $screen-xs-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include responsive-visibility('.visible-sm');
}

.visible-sm-block {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: block !important;
  }
}

.visible-sm-inline {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: inline !important;
  }
}

.visible-sm-inline-block {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @include responsive-visibility('.visible-md');
}

.visible-md-block {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: block !important;
  }
}

.visible-md-inline {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: inline !important;
  }
}

.visible-md-inline-block {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: inline-block !important;
  }
}

@media (min-width: $screen-lg-min) {
  @include responsive-visibility('.visible-lg');
}

.visible-lg-block {
  @media (min-width: $screen-lg-min) {
    display: block !important;
  }
}

.visible-lg-inline {
  @media (min-width: $screen-lg-min) {
    display: inline !important;
  }
}

.visible-lg-inline-block {
  @media (min-width: $screen-lg-min) {
    display: inline-block !important;
  }
}

@media (max-width: $screen-xs-max) {
  @include responsive-invisibility('.hidden-xs');
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include responsive-invisibility('.hidden-sm');
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @include responsive-invisibility('.hidden-md');
}

@media (min-width: $screen-lg-min) {
  @include responsive-invisibility('.hidden-lg');
}
