.main-footer {
    padding: 50px 0;
	margin: 40px 0 0 0;
	text-align: center;
	color: #fff;
    background: $brand-primary;
	img {
		width: 30px;
		margin-right: 7px;
		line-height: 1;
	}
	p {
		margin-bottom: 0;
		display: inline-block;
		padding: 5px 30px;
	}
	.timcoysh {
		a {
			color: #fff;
			&:hover {
				text-decoration: underline;
			}
		}
		svg {
			display: inline-block;
			height: 20px;
			position: relative;
			top: 5px;
			padding: 0 5px;
		}
	}
}
