/**
 *  TYPOGRAPHY
 */

body {
  font: #{$font-weight-base} #{$font-size-base}/#{$line-height-base} $basefont;
  color: $text-body;

  @include breakpoint(bp-medium) {
    font-size: $font-size-base-medium;
  }

  @include breakpoint(bp-large) {
    font-size: $font-size-base-large;
  }
}

img {
  font-size: 12px;
  line-height: 1.3em;
}

p {
  line-height: $line-height-base;
	font-weight: $font-weight-light;
}

a {
  color: $brand-secondary;
  text-decoration: none;

  &:hover {
    color: rgba($brand-secondary, .8);
  }

  &:focus {
    color: $brand-secondary;
  }
}


// titles commons
.text {
  &-huge, &-big, &-medium {
    line-height: 1.3em;
    margin-bottom: 1em;
    margin-top: 1.5em;

    // titles in sections
    &:first-child {
      margin-top: .5em;
    }
  }

  // titles

  &-huge {
    font-family: $heading-font;
    font-size: 30px;
    font-weight: $heading-weight;
    text-transform: $heading-case;

    @include breakpoint(bp-large) {
      font-size: 40px;
    }

    @include breakpoint(bp-huge) {
      font-size: 48px;
    }
  }

  &-big {
    font-family: $heading-font;
    font-size: 24px;
    font-weight: $heading-weight;
    text-transform: $heading-case;

    @include breakpoint(bp-large) {
      font-size: 28px;
    }
  }

  &-medium {
    font-family: $heading-font;
    font-size: 20px;
    font-weight: $heading-weight;
    text-transform: $heading-case;
  }

  &-small {
    font-size: 14px;

    @include breakpoint(bp-medium) {
      font-size: 16px;
    }
  }

  // force element to default font state.
  &-body {
    font-size: $font-size-base;
    line-height: 1.5em;

    @include breakpoint(bp-large) {
      font-size: $font-size-base-large;
    }
  }

  // Colours
  @each $type, $color in $background-color-map {
    &-#{$type} {
      color: $color;
    }
  }

  &-white {
    color: $white;
  }
}

.text-gray,
a.text-gray,
button.text-gray {
  color: $text-gray;
}

// Styles
.text-light {
  font-weight: 300;
}

.text-normal {
  font-weight: 400;
}

.text-line-through {
  text-decoration: line-through;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-transform-none {
  text-transform: none;
}

.text-center {
  text-align: center;
}

.text-lato {
  font-family: 'Lato', sans-serif;
}

// titles after titles
.text-with-subtitle {
  margin-bottom: 0!important;

  + .text-huge,
  + .text-big,
  + .text-medium,
  + .text-small, {
    margin-top: .5em;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: $heading-weight;
	line-height: 1;
  margin-bottom: 20px;
  font-family: $headingfont;
}


$min_width: $screen-xs;
$max_width: $screen-lg;
$min_font: 16px;
$max_font: 24px;
$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop

h1 {
  font-size: $mod_1*$mod_1*$mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font,  $mod_1*$mod_1*$mod_1 *$max_font);
}
h2 {
  font-size: $mod_1*$mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1*$mod_1 *$min_font,  $mod_1*$mod_1 *$max_font);
}
h3 {
  font-size: $mod_1*$mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1*$mod_1 *$min_font, $mod_1 *$max_font);
}
h4 {
  font-size: $mod_1 *1rem;
  @include fluid-type($min_width, $max_width, $mod_1 *$min_font, $mod_2 *$min_font);
}
p {

}
