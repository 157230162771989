/**
 *  PAGINATOR
 *
 *  Markup:
 *  -------
 *
 *  <ul class="paginator-list">
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link">Prev</a>
 *    </li>
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link">1</a>
 *    </li>
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link active">2</a>
 *    </li>
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link">3</a>
 *    </li>
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link">Next</a>
 *    </li>
 *  </ul>
 */

.paginator-list {
	text-align: center;
  .paginator-list-item {
    margin-right: 5px;
    padding: 0;
    display: inline-block;

    &:first-child {
      i {
        margin-right: .5em;
      }
    }

    &:last-child {
      i {
        margin-left: .5em;
      }
    }

    .paginator-list-link {
      background-color: $paginator-item-bg;
      border: 1px solid $paginator-item-border;
      border-radius: $paginator-item-radius;
      color: $paginator-item-color;
      display: block;
      padding: .5em 1em;
      transition: background-color .2s ease-in-out;

      &:hover {
        background-color: $brand-light;
      }

      &.active {
        background-color: $paginator-item-active-bg;
        color: $paginator-item-active-color;
      }
    }
  }

  // compact
  &.paginator-compact {
    display: flex;

    .paginator-list-item {
      margin-right: 0;

      &:not(:last-child) {
        border-right: 1px solid $paginator-item-border;
      }

      .paginator-list-link {
        border-radius: 0;
      }

      &:first-child .paginator-list-link {
        border-bottom-left-radius: $radius;
        border-top-left-radius: $radius;
      }

      &:last-child .paginator-list-link {
        border-bottom-right-radius: $radius;
        border-top-right-radius: $radius;
      }
    }
  }

  // centered
  &.paginator-center {
    justify-content: center;
    text-align: center;
  }
}
