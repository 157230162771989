.body-container {
	height: 100vh;
}

.vert-center {
	@include vertical-align();
}
.phone-sss {

	font-size: 35px;
	font-weight: 800;
	margin: 30px 0;
	display: inline-block;
	color: #fff;
	text-decoration: none;
}

.btn-home {
	display: inline-block;
	margin: 15px 0;
	border: 3px solid white;
	font-size: 20px;
	border-radius: 3px;
	color: #fff;
	padding: 12px 20px;
	text-transform: uppercase;
	transition: all .2s;
	&:hover {
		color: #fff;
		background: lighten($brand-primary, 3%);
	}
}

.no-js-card-img {
	width: 100%;
}

.row {
	display: flex;
}
.valign {
	position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.subscription {
	background: $brand-tertiary;
	padding: 80px 0;
	h2 {
		color: $brand-primary;
		margin-bottom: 50px;
	}
	.input {
		width: 100%;
	}
	.button {
		margin-top: 25px;
	}

	@include media("<tablet") {
		padding: 50px 0;
		h2 {
			margin-bottom: 30px;
		}
	}
}
.form {
	.input {
		width: 100%;
	}
}