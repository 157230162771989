$block-margin-px: 40px;
$block-margin: 10px 0 40px 0;
body.page {
	background: #fff; //For scrolling up on phones/ipads
	.title-image {
		position: absolute;
		top: 0;
		left: 0;
		height: 30vh;
		width: 100%;
		min-height: 400px;
		background: $brand-primary; //For slow connections
		transition: height .2s;
		img {
			height: 0;
			object-fit: cover;
			width: 100%;
			padding-bottom: 56.25%;
			&.lazyloaded {
				height: 100%;
				padding: 0;
			}
		}
		.hero-overlay {
			position: absolute;
			opacity: 0.55;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: #048ccc; //$brand-primary;
		}
	}
	.title-container {
		position: relative;
		padding-top: 200px;
		height: 30vh;
		min-height: 400px;
		text-align: center;
		margin-bottom: 40px;
		h1 {
			font-size: 50px;
			line-height: 1.1;
			color: #fff;
			text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.45)
		}
		&.no-featured-image {
			height: auto;
			padding: calc(100px + 70px) 0 70px;
			min-height: 0;
			background: #ebf5fb;
			h1 {
				color: $text-body;
				padding-bottom: 7px;
				border-bottom: 4px solid #e82772;
				text-shadow: none;
				display: inline-block;
			}
		}
	}
	.page-content {
		margin: 30p 0;
		h1 {
			margin-bottom: 10px;
		}
		.heading {
			text-align: center;
			h1 {
				margin: $block-margin-px 0;
			}
		}
		.text {
			margin: 0px 0px 40px 20px;
		}
		h4 {
			font-weight: 100;
			font-size: 20px;
			line-height: 1.6;
			margin: 0 0 30px 0;
		}
	
		a:not(.button) {
			border-bottom: 1px solid #000;
			padding-bottom: 1px;
			&:hover {
				border-bottom: 1px solid rgba(0,0,0,0);
			}
		}
		p {
			line-height: 1.8em;
			margin-bottom: 1em;
			font-weight: 300;
			font-size: 20px;
		}
		img {
			border-radius: 3px;
		}
		.block-image {
			&.book {
				text-align: center;
				margin: 0 10px;
				img {
					max-width: 150px;
				}
				p {
					line-height: 1.3em;
					margin-top: 10px;
					margin-bottom: 30px;
				}
			}
		}
		.embed-wrapper {
			position: relative;
			padding-bottom: 56.25%;
			iframe{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		.button-container {
			text-align: center;
		}
		.testimonial-quote {
			position: relative;
			margin: $block-margin-px 0 #{$block-margin-px+100} 0;
			.quote-image {
				img {
					margin-top: 60px;
					box-shadow: 0 0 20px -3px #6b6b6b;
					display: block;
					max-width: 100%;
					border-radius: 14px;
				}
			}
			.quote-container {
				padding-left: 40px; 
			}
			blockquote {
				position: relative;
				p {
					line-height: 1.5;
					font-size: 23px;
					&:first-child:before {
						content: '\201C';
						color: #fff;
						background: $brand-primary;
						font-size: 42px;
						line-height: 1;
						font-weight: 700;
						text-shadow: none;
						padding: 5px 8px;
						width: 40px;
						height: 40px;
						border-radius: 50%;
						display: block;
						margin-bottom: 20px;
					}
				}
			}
			cite {
				color: gray;
				display: block;
				font-size: .8em; 
				span {
					color: #5e5e5e;
					font-weight: 700;
					font-style: normal;
					letter-spacing: 1px;
					text-transform: uppercase;
					text-shadow: 0 1px white; 
				}
			}
			&.right {
				.quote-container {
					padding-left: 0;
					padding-right: 40px; 
				}
				cite {
					text-align: right; 
				}
			}
		}
		.well {
			position: relative;
			padding: 50px 0;
			margin: $block-margin;
			text-align: center;
			&:before {
				content: " ";
				background: #ebf5fb;
				position: absolute;
				left: -100%;
				top: 0;
				width: 300%;
				height: 100%;
				z-index: -1;
				transition: all 1.5s ease-out;
				transition-delay: 0;
				opacity: 1;
			}
		}
		.spacer {
			height: 50px
		}
	}
	.index-cards {
		margin-top: $block-margin-px;
		h3 {
			border-bottom: 4px solid #e43879;
			display: inline-block;
			padding-bottom: 5px;
			margin-bottom: 30px;
		}
	}
}

.pull-right {
	float: right;
}
.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}