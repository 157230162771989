/**
 * LAYOUT
 */

.section {
  @include clearfix();

  padding-bottom: 20px;
  padding-top: 20px;

  @include breakpoint(bp-medium) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

}

.container {
  margin: 0 auto;
  max-width: $container-maxwidth;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
  width: 100%;

  @include breakpoint(bp-medium) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

.container-medium {
  margin: 0 auto;
  max-width: $container-medium-maxwidth;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  @include breakpoint(bp-medium) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

.container-small {
  margin: 0 auto;
  max-width: $container-small-maxwidth;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;

  @include breakpoint(bp-medium) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}

.full-width {
  @extend %full-width;
}

// separator
.separator {
  border-bottom: 1px solid $border-color;
}


// Specific selector for mobonaut. Must delete ASAP.
.main-wrap {
  overflow: hidden;
  position: relative;
  transition: padding .25s ease-in-out;

  @include breakpoint(bp-huge) {
    padding-left: 250px;
  }
}

// Specific selector for mobonaut & appszoom. Must delete ASAP.
.main-content {
  float: right;
  margin-left: 0;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before,
  &::after {
    clear: none;
  }
}
