@charset "UTF-8";
@import url("https://use.typekit.net/igg1tue.css");
/**
 *  VARIABLES
 *
 *  Configure sierra SCSS library by adjusting this file
 *
 */
/**
 *  GRID SYSTEM
 *  set $flexboxgrid to `true` to use a flexbox based grid. More info: https://github.com/kristoferjoseph/flexboxgrid
 *  set $flexboxgrid to `false` to use Twitter bootstrap's grid. More info: https://getbootstrap.com/examples/grid/
 */
/**
 *  LAYOUT
 */
/**
 *  MEDIA QUERIES BREAKPOINTS
 */
/**
 *  BOOTSTRAP GRID SYSTEM CONFIG
 */
/**
 *  COMPONENTS
 */
/**
 * Library
 */
/**
 * MIXINS
 */
/**
 *  PLACEHOLDERS
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.full-width {
  margin-left: -15px;
  margin-right: -15px;
  width: auto; }
  @media (min-width: 768px) {
    .full-width {
      margin-left: -30px;
      margin-right: -30px; } }

/**
 * Reset
 */
html, body, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline; }

article,
aside,
figure,
footer,
header,
main,
nav,
section {
  display: block; }

*, *:before, *:after {
  box-sizing: border-box; }

/**
 *  MAIN RULES
 */
*,
*::after,
*::before {
  box-sizing: border-box; }

body {
  background-color: #fff;
  font-weight: normal;
  min-height: 100%;
  overflow-x: hidden;
  position: relative; }

p {
  font-weight: normal;
  margin-bottom: 1em; }

img {
  max-width: 100%; }

strong {
  font-weight: 600; }

ul {
  margin-bottom: 1em; }

li {
  list-style: none;
  margin-bottom: .5em; }

pre {
  margin-bottom: 2em !important; }

/**
 *  BACKGROUND COLORS
 */
.background-primary {
  background-color: #1595d1; }

.background-dark {
  background-color: #18537a; }

.background-secondary {
  background-color: #e43879; }

.background-white {
  background-color: #ffffff; }

.background-success {
  background-color: #4caf50; }

.background-info {
  background-color: #5bc0de; }

.background-warning {
  background-color: #f0ad4e; }

.background-error {
  background-color: #e74c3c; }

.background-gray {
  background-color: #838383; }

.background-gray-light {
  background-color: #edf3f6; }

.background-rating1 {
  background-color: #dd2c00; }

.background-rating2 {
  background-color: #ff5722; }

.background-rating3 {
  background-color: #ff9800; }

.background-rating4 {
  background-color: #ffc107; }

.background-rating5 {
  background-color: #f5dc00; }

.background-rating6 {
  background-color: #cddc39; }

.background-rating7 {
  background-color: #8bc34a; }

.background-rating8 {
  background-color: #4caf50; }

.background-rating9 {
  background-color: #43a047; }

.background-rating10 {
  background-color: #388e3c; }

/**
 *  BUTTONS
 */
button {
  background-color: transparent;
  border: 0;
  cursor: pointer; }

.button {
  background-color: #e43879;
  border: 1px solid #e43879;
  border-radius: 7px;
  color: #1595d1;
  display: inline-block;
  font-family: "bilo", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.5em;
  margin: 10px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: opacity .2s ease-in-out;
  white-space: nowrap; }
  .button:focus, .button:hover, .button:active {
    color: #1595d1; }
  .button:hover {
    cursor: pointer;
    opacity: .8;
    text-decoration: none; }
  .button:active {
    opacity: 1; }
  .button:first-of-type {
    margin-left: 0; }
  .button.button-big {
    border-radius: 7px;
    font-size: 16px;
    line-height: 1.5em;
    padding: 10px 30px; }
    @media (min-width: 992px) {
      .button.button-big {
        padding: 10px 40px; } }
  .button.button-small {
    border-radius: 7px;
    font-size: 11px;
    line-height: 1.273em;
    padding: 6px 20px; }
  .button.button-huge {
    border-radius: 7px;
    font-size: 16px;
    line-height: 1.5em;
    padding: 15px 30px; }
    @media (min-width: 992px) {
      .button.button-huge {
        border-radius: 7px;
        font-size: 18px;
        line-height: 1.5em;
        padding: 18px 50px; } }
    .button.button-huge i {
      font-size: 20px; }
  .button.button-large {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    width: 100%; }
  .button.button-primary {
    background-color: #1595d1;
    border: 1px solid #1595d1;
    color: #ffffff; }
    .button.button-primary:hover {
      color: #ffffff; }
  .button.button-secondary {
    background-color: #e43879;
    border: 1px solid #e43879;
    color: #ffffff; }
    .button.button-secondary:hover {
      color: #ffffff; }
  .button.button-transparent {
    background-color: transparent;
    border: 1px solid transparent; }
  .button.button-white {
    background-color: #ffffff;
    border: 1px solid #ffffff;
    color: #1595d1; }
  .button.button-green {
    background-color: #4caf50;
    border-color: #4caf50;
    color: #ffffff; }
  .button.button-red {
    background-color: #e74c3c;
    border-color: #e74c3c;
    color: #ffffff; }
  .button.button-outlined {
    background-color: transparent;
    border: 1px solid #1595d1;
    color: #1595d1;
    transition: all .2s ease-in-out; }
    .button.button-outlined.button-white {
      background-color: transparent;
      border: 1px solid #fff;
      color: #fff; }
      .button.button-outlined.button-white:hover {
        border: 1px solid #e43879;
        background-color: #e43879;
        opacity: 1;
        color: #fff; }
    .button.button-outlined:hover {
      border: 1px solid #e43879;
      background-color: #e43879;
      opacity: 1;
      color: #fff; }
  .button.button-disabled {
    cursor: default;
    opacity: .4; }
  .button.button-only-icon {
    height: 43px;
    line-height: 43px;
    padding: 0;
    width: 43px; }
    .button.button-only-icon i {
      margin-left: 0;
      margin-right: 0; }
  .button i {
    margin-bottom: -2%;
    margin-left: .8em;
    margin-right: .8em;
    position: relative;
    transition: all .3s ease; }
    .button i:last-child {
      margin-right: 0; }
    .button i:first-child {
      margin-left: 0; }
    .button i::before {
      float: left; }
    .button i.fa-lg {
      margin-bottom: 0; }

.button-link {
  color: #1595d1;
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase; }
  .button-link i {
    font-size: 18px;
    line-height: 14px;
    margin-bottom: -2px;
    margin-left: 4px;
    margin-right: 8px;
    transition: all .3s ease; }
    .button-link i::before {
      float: left; }
  .button-link.button-link-rotated i {
    transform: rotate(-180deg); }

.button-group {
  white-space: nowrap; }
  .button-group .button {
    display: inline-block;
    margin: 0; }
    .button-group .button:not(:first-child) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; }
    .button-group .button:not(:last-child) {
      border-bottom-right-radius: 0;
      border-right: 0;
      border-top-right-radius: 0; }

/**
 *  FORMS
 */
input,
textarea {
  appearance: none; }

label {
  display: block;
  font-weight: normal; }

input:-webkit-autofill {
  box-shadow: 0 0 0 1000px #efefef inset; }

select {
  -moz-appearance: window;
  -webkit-appearance: none; }

select,
input[type=text],
input[type=password],
textarea {
  border-radius: 3px;
  font-size: 17px;
  line-height: 1.5em;
  transition: background-color .2s ease-in-out; }
  @media (min-width: 768px) {
    select,
    input[type=text],
    input[type=password],
    textarea {
      font-size: 17px; } }
  @media (min-width: 992px) {
    select,
    input[type=text],
    input[type=password],
    textarea {
      font-size: 17px; } }

.textarea,
.input,
.select {
  border: 0;
  border: 1px solid #dddddd;
  border-radius: 3px;
  box-shadow: none;
  display: block;
  font-weight: normal;
  margin-bottom: 20px; }
  .textarea.error,
  .input.error,
  .select.error {
    border: 1px solid #e74c3c;
    margin-bottom: 0; }
  .textarea.small,
  .input.small,
  .select.small {
    font-size: 13px;
    padding: 4px 6px; }

.has-error .textarea,
.has-error .input,
.has-error .select {
  border: 1px solid #1595d1; }

.has-error .control-label {
  color: #1595d1; }

label.error {
  color: #e74c3c;
  font-size: 13px;
  line-height: 13px;
  position: absolute;
  right: 0;
  top: -16px; }

input[type=checkbox] {
  appearance: checkbox; }

input[type=radio] {
  appearance: radio; }

.select {
  background-color: #efefef;
  display: inline-block;
  margin-right: 10px;
  padding: 0;
  position: relative; }
  .select.full-width {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .select:last-child {
    margin-right: 0; }
  .select .fa-angle-down {
    color: #969da6;
    font-size: 25px;
    height: 30px;
    margin-top: -12px;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 1; }
  .select .icon-angle-down {
    color: #1595d1;
    font-size: 1.2em;
    height: 30px;
    margin-top: -10px;
    position: absolute;
    right: 10px;
    top: 50%;
    z-index: 1; }
  .select select {
    appearance: none;
    background-color: transparent;
    border: 0;
    color: #0a0a0a;
    height: 44px;
    margin-right: 20px;
    padding-left: 10px;
    padding-right: 30px;
    position: relative;
    width: 100%;
    z-index: 2; }
    @media (min-width: 768px) {
      .select select {
        padding: 8px 15px; } }
    .select select:active, .select select:focus {
      border: 0; }
    .select select:active + i,
    .select select:focus + i {
      z-index: 2; }
    .select select option {
      background-color: #efefef;
      color: #0a0a0a;
      height: 30px; }
  .select.select-small {
    max-width: 150px; }
    .select.select-small select {
      font-size: 14px !important;
      height: 24px;
      line-height: 24px;
      padding: 0 5px;
      padding-right: 27px; }

select::-ms-expand {
  display: none; }

.select-link {
  border: 0;
  color: #1595d1; }

.textarea {
  background-color: #efefef;
  padding: 0;
  width: 100%; }
  .textarea textarea {
    background: transparent;
    border: 0;
    color: #0a0a0a;
    display: block;
    font-family: "bilo", "Helvetica", "Arial", sans-serif;
    padding: 10px 15px;
    width: 100%; }
    .textarea textarea:focus, .textarea textarea:active {
      border: 0;
      outline: none;
      background-color: #fcfcfc; }

textarea {
  min-height: 120px; }

.form-button-wrap {
  text-align: center; }
  @media (min-width: 768px) {
    .form-button-wrap {
      text-align: right; } }

/**
 *  CHECKBOXES AND RADIO BUTTONS
 *
 *  Markup:
 *  -------
 *
 *  <div class="radio">
 *    <input id="hello" name="hello" type="radio" value=""/>
 *    <label for="hello" >option 1</label>
 *  </div>
 *
 *  <div class="checkbox">
 *    <input id="goodbye" name="goodbye" type="checkbox" value=""/>
 *    <label for="goodbye">option {{$i}}</label>
 *  </div>
 *
 */
.checkbox,
.radio {
  margin: 0;
  margin-bottom: 5px;
  margin-right: 20px;
  padding-left: 25px;
  position: relative; }

input[type=checkbox] + label,
input[type=radio] + label {
  display: block;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap; }

input[type=checkbox] {
  display: none; }

input[type=radio] {
  display: none; }

input[type=checkbox] + label:after,
input[type=checkbox] + label:before,
input[type=radio] + label:after,
input[type=radio] + label:before {
  display: block;
  position: absolute;
  font-size: 14px;
  height: 18px;
  line-height: 18px;
  margin-top: -9px;
  top: 50%;
  width: 18px;
  left: 0; }

input[type=checkbox] + label:after,
input[type=radio] + label:after {
  background-color: #efefef;
  border: 1px solid #dddddd;
  border-radius: 3px;
  content: ''; }

input[type=radio] + label:after {
  border-radius: 99px; }

input[type=checkbox] + label:before {
  content: "\f00c";
  font-family: FontAwesome;
  left: 2px;
  top: 11px;
  transform: scale(0);
  z-index: 1; }

input[type=radio] + label:before {
  background-color: #1595d1;
  border-radius: 99px;
  content: '';
  display: block;
  height: 8px;
  left: 5px;
  margin-top: -4px;
  top: 50%;
  transform: scale(0);
  width: 8px;
  z-index: 1; }

input[type=checkbox]:checked + label,
input[type=radio]:checked + label {
  color: #1595d1; }

input[type=checkbox]:checked + label:before,
input[type=radio]:checked + label:before {
  animation: radioAndCheckboxAnimation .25s;
  color: #1595d1;
  transform: scale(1); }

@keyframes radioAndCheckboxAnimation {
  0% {
    transform: scale(0); }
  50% {
    transform: scale(1.5); }
  100% {
    transform: scale(1); } }

.input {
  background-color: #efefef;
  display: inline-block;
  margin-right: 10px;
  padding: 0;
  position: relative; }
  .input.full-width {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .input input {
    background: transparent;
    border: 0;
    box-shadow: none;
    color: #0a0a0a;
    height: 44px;
    line-height: 44px;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%; }
    @media (min-width: 768px) {
      .input input {
        padding: 8px 15px; } }

.input-with-icon {
  position: relative; }
  .input-with-icon input {
    padding-right: 42px; }
  .input-with-icon .input-icon {
    color: #969da6;
    margin-top: -6px;
    position: absolute;
    right: 12px;
    top: 50%; }
    .input-with-icon .input-icon.fa-search {
      color: #1595d1;
      margin-top: -7px; }
  .input-with-icon button.input-icon {
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0; }

.input-icon input {
  padding: 0;
  width: 0; }

.input-icon i {
  padding: 14px 15px 14px 12px; }

/**
 *  Input group
 *
 *  Markup:
 *  -------
 *
 *  <div class="input-group {{ $errors->first('email', 'has-error') }}">
 *     <div class="input full-width ">
 *         <input type="email" class="form-control" name="email" placeholder="email address" value="{{{ Input::old('email') }}}">
 *     </div>
 *     <span class="help-block">{{ $errors->first('email', ':message') }}</span>
 *  </div>
 *
 */
.input-group {
  margin-bottom: 20px; }
  .input-group .textarea,
  .input-group .input,
  .input-group .select {
    margin-bottom: 0; }

/**
 *  FORM GROUP
 *
 *  Markup:
 *  -------
 *
 *  <div class="form-group">
 *    <div class="input form-group-item">
 *      <input type="text" name="" id="" placeholder="Search an app and start tracking...">
 *    </div>
 *    <div class="select form-group-item">
 *      <select class="class">
 *        <option value="asd">asd</option>
 *        <option value="asfdasd">asfdasd</option>
 *      </select>
 *      <i class="js-select-opener fa fa-angle-down fa-2"></i>
 *    </div>
 *  </div>
 */
.form-group {
  background-color: #efefef;
  border: 1px solid #dddddd;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 20px; }
  .form-group .textarea,
  .form-group .input,
  .form-group .select {
    margin-bottom: 0; }
  .form-group .form-group-item {
    border: 0;
    border-radius: 0;
    margin-right: 0; }
    .form-group .form-group-item:last-child {
      border-right: 0; }

.form-row {
  margin-left: -3px !important;
  margin-right: -3px !important; }

.form-row div[class^="col"] {
  padding-left: 3px;
  padding-right: 3px; }

/**
 *  FORM COLLAPSE (items in row without gap between them)
 *
 *  Markup:
 *  -------
 *
 *  <div class="form-collapse">
 *    <div class="input item item-main">
 *      <input id="name" placeholder="Klingon search" type="text" />
 *    </div>
 *    <div class="select item">
 *      <select name="country-code" id="country-code">
 *        <option value="AO"> Angola</option>
 *      </select>
 *      <i class="fa fa-angle-down fa-2"></i>
 *    </div>
 *    <button class="item button button-primary">
 *      Search
 *    </button>
 *  </div>
 **/
.form-collapse {
  display: flex;
  margin-bottom: 20px; }
  .form-collapse .item-main {
    flex: 1; }
  .form-collapse .item {
    border-radius: 0;
    font-size: 15px;
    margin: 0; }
    .form-collapse .item:first-child {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px; }
    .form-collapse .item:last-child {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px; }
    .form-collapse .item:not(:last-child) {
      border-right: 0; }

/**
 *  FLEX ALIGNMENTS
 */
.aligner {
  display: flex; }

.aligner-space-between {
  display: flex;
  justify-content: space-between;
  width: 100%; }

.aligner-space-around {
  display: flex;
  justify-content: space-around;
  width: 100%; }

.aligner-center-vertical {
  align-items: center;
  display: flex; }

.aligner-center-horitzontal {
  display: flex;
  justify-content: center; }

.content-start {
  display: flex;
  justify-content: flex-start; }

.content-end {
  display: flex;
  justify-content: flex-end; }

.aligner-item-top {
  align-self: flex-start; }

.aligner-item-bottom {
  align-self: flex-end; }

.flex-grow {
  flex-grow: 1; }

/**
 *  ALIGNMENTS
 */
.fleft {
  float: left; }

.fright {
  float: right; }

.cf::before, .cf::after {
  content: '';
  display: table; }

.cf::after {
  clear: both; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.align-left {
  text-align: left; }

.align-bottom {
  vertical-align: bottom; }

.align-middle {
  vertical-align: middle; }

.align-top {
  vertical-align: top; }

/**
 *  TEXT UTILITIES
 */
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.ellipsis-block {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block; }

.word-break {
  word-break: break-all; }

.text-indent {
  padding-left: 24px; }

/**
 *  MODIFIERS
 */
.bordered {
  border: 1px solid #dddddd; }

.border-bottom {
  border-bottom: 1px solid #dddddd; }

.border-left {
  border-left: 1px solid #dddddd; }

.border-right {
  border-right: 1px solid #dddddd; }

.border-top {
  border-top: 1px solid #dddddd; }

.display-block {
  display: block; }

@media (max-width: 480px) {
  .block-mobile {
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: 100%; } }

.display-inline {
  display: inline; }

.hidden {
  display: none; }

.no-margin {
  margin: 0 !important; }

.no-wrap {
  white-space: nowrap; }

.overflow-hidden {
  overflow: hidden; }

.opacity-low {
  opacity: .5; }

.icon-rounded,
.rounded-corners {
  border-radius: 15%; }

.rounded {
  border-radius: 100%; }

/**
 *  VISIBILITY UTILITIES
 */
@media (min-width: 992px) {
  .hidden-large {
    display: none; } }

@media (min-width: 1280px) {
  .hidden-large {
    display: inline-block; } }

@media (min-width: 768px) {
  .hidden-medium {
    display: none; } }

@media (min-width: 992px) {
  .hidden-medium {
    display: inline-block; } }

@media (min-width: 768px) {
  .show-small {
    display: none; } }

.hide-small {
  display: none; }
  @media (min-width: 768px) {
    .hide-small {
      display: block; } }

.hide-small-inline-block {
  display: none !important; }
  @media (min-width: 768px) {
    .hide-small-inline-block {
      display: inline-block !important; } }

.hide-medium {
  display: block; }
  @media (min-width: 768px) {
    .hide-medium {
      display: none; } }
  @media (min-width: 992px) {
    .hide-medium {
      display: block; } }

/**
 *  BOOTSTRAP VISIBILITY UTILITIES
 *  Note: Deprecated .visible-xs, .visible-sm, .visible-md, and .visible-lg as of v3.2.0
 */
.visible-xs {
  display: none !important; }

.visible-sm {
  display: none !important; }

.visible-md {
  display: none !important; }

.visible-lg {
  display: none !important; }

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important; }

@media (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; } }

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important; } }

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important; } }

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .visible-md-block {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .visible-md-inline {
    display: inline !important; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .visible-md-inline-block {
    display: inline-block !important; } }

@media (min-width: 1280px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; } }

@media (min-width: 1280px) {
  .visible-lg-block {
    display: block !important; } }

@media (min-width: 1280px) {
  .visible-lg-inline {
    display: inline !important; } }

@media (min-width: 1280px) {
  .visible-lg-inline-block {
    display: inline-block !important; } }

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1279px) {
  .hidden-md {
    display: none !important; } }

@media (min-width: 1280px) {
  .hidden-lg {
    display: none !important; } }

/**
 * LAYOUT
 */
.section {
  padding-bottom: 20px;
  padding-top: 20px; }
  .section::before, .section::after {
    content: '';
    display: table; }
  .section::after {
    clear: both; }
  @media (min-width: 768px) {
    .section {
      padding-bottom: 40px;
      padding-top: 40px; } }

.container {
  margin: 0 auto;
  max-width: 1380px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%; }
  @media (min-width: 768px) {
    .container {
      padding-left: 30px;
      padding-right: 30px; } }

.container-medium {
  margin: 0 auto;
  max-width: 944px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .container-medium {
      padding-left: 30px;
      padding-right: 30px; } }

.container-small {
  margin: 0 auto;
  max-width: 400px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .container-small {
      padding-left: 30px;
      padding-right: 30px; } }

.separator {
  border-bottom: 1px solid #dddddd; }

.main-wrap {
  overflow: hidden;
  position: relative;
  transition: padding .25s ease-in-out; }
  @media (min-width: 1280px) {
    .main-wrap {
      padding-left: 250px; } }

.main-content {
  float: right;
  margin-left: 0;
  overflow: hidden;
  position: relative;
  width: 100%; }
  .main-content::before, .main-content::after {
    clear: none; }

/**
 *  MESSAGE BARS
 *
 *  Markup:
 *  -------
 *
 *  <div class="message-bar background-sucess">Success message</div>
 *  <div class="message-bar background-info">Success info</div>
 *  <div class="message-bar background-error">Success error</div>
 *  <div class="message-bar background-warning">Success warning</div>
 *
 */
.message-bar {
  border-radius: 3px;
  margin-bottom: 1em;
  padding: 1em; }
  .message-bar p:last-child {
    margin-bottom: 0; }
  .message-bar.background-success, .message-bar.background-warning, .message-bar.background-error {
    color: #fff; }

/**
 *  PAGINATOR
 *
 *  Markup:
 *  -------
 *
 *  <ul class="paginator-list">
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link">Prev</a>
 *    </li>
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link">1</a>
 *    </li>
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link active">2</a>
 *    </li>
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link">3</a>
 *    </li>
 *    <li class="paginator-list-item">
 *      <a href="#" class="paginator-list-link">Next</a>
 *    </li>
 *  </ul>
 */
.paginator-list {
  text-align: center; }
  .paginator-list .paginator-list-item {
    margin-right: 5px;
    padding: 0;
    display: inline-block; }
    .paginator-list .paginator-list-item:first-child i {
      margin-right: .5em; }
    .paginator-list .paginator-list-item:last-child i {
      margin-left: .5em; }
    .paginator-list .paginator-list-item .paginator-list-link {
      background-color: "none";
      border: 1px solid #fff;
      border-radius: 5px;
      color: #0a0a0a;
      display: block;
      padding: .5em 1em;
      transition: background-color .2s ease-in-out; }
      .paginator-list .paginator-list-item .paginator-list-link:hover {
        background-color: #efefef; }
      .paginator-list .paginator-list-item .paginator-list-link.active {
        background-color: #e43879;
        color: #1595d1; }
  .paginator-list.paginator-compact {
    display: flex; }
    .paginator-list.paginator-compact .paginator-list-item {
      margin-right: 0; }
      .paginator-list.paginator-compact .paginator-list-item:not(:last-child) {
        border-right: 1px solid #fff; }
      .paginator-list.paginator-compact .paginator-list-item .paginator-list-link {
        border-radius: 0; }
      .paginator-list.paginator-compact .paginator-list-item:first-child .paginator-list-link {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px; }
      .paginator-list.paginator-compact .paginator-list-item:last-child .paginator-list-link {
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px; }
  .paginator-list.paginator-center {
    justify-content: center;
    text-align: center; }

/**
 *  TABLES
 */
.table {
  background-color: #efefef;
  border: 1px solid #dddddd;
  border-collapse: collapse;
  border-radius: 3px;
  color: #0a0a0a;
  margin-bottom: 20px;
  max-width: 100%;
  width: 100%; }
  .table th,
  .table td {
    border-bottom: 1px solid #dddddd;
    padding: 10px; }
    @media (min-width: 992px) {
      .table th,
      .table td {
        padding: 10px 20px; } }
  .table td {
    padding: 14px;
    position: relative; }
  .table tr {
    border-bottom: 1px solid #dddddd; }
  .table thead {
    border-bottom: 1px solid #dddddd; }
  .table th {
    background-color: #fff;
    color: #969da6;
    font-weight: normal;
    padding: 5px 14px;
    white-space: nowrap; }

.table-vertical-center td {
  vertical-align: middle; }

/**
 *  TABLE RESPONSIVE
 *
 *  Markup:
 *  -------
 *
 *  <table class="table-responsive table-break-small">
 *    <tr>
 *      <th>First column</th>
 *      <th>Second column</th>
 *      <th>Third column</th>
 *    </tr>
 *    <tr>
 *      <td data-th="First column">Blue</td>
 *      <td data-th="Second column">One</td>
 *      <td data-th="Third column">My life fades</td>
 *    </tr>
 *    <tr>
 *      <td data-th="First column">Green</td>
 *      <td data-th="Second column">Two</td>
 *      <td data-th="Third column">when the world was powered by the black fuel... and the desert sprouted great cities of pipe and steel. </td>
 *    </tr>
 *    <tr>
 *      <td data-th="First column">Yellow</td>
 *      <td data-th="Second column">Three</td>
 *      <td data-th="Third column">A whirlwind of looting, a firestorm of fear. Men began to feed on men. </td>
 *    </tr>
 *  </table>
 *
 */
.table-responsive th {
  display: none; }

.table-responsive td {
  display: block; }
  .table-responsive td:first-child {
    border-top: 1px solid #dddddd;
    padding-top: 14px; }
  .table-responsive td:last-child {
    padding-bottom: 14px; }
  .table-responsive td::before {
    content: attr(data-th) ": ";
    display: block;
    font-weight: normal; }

.table-responsive th,
.table-responsive td {
  text-align: left; }

.table-responsive.table-break-medium tr {
  border-top: 1px solid #dddddd; }

@media (max-width: 768px) {
  .table-responsive.table-break-medium td {
    border: 0;
    padding-bottom: 0; } }

@media (min-width: 768px) {
  .table-responsive.table-break-medium td::before {
    display: none; } }

.table-responsive.table-break-medium td:last-child {
  padding-bottom: 14px; }

@media (min-width: 768px) {
  .table-responsive.table-break-medium th,
  .table-responsive.table-break-medium td {
    display: table-cell; } }

.table-responsive.table-break-small tr {
  border-top: 1px solid #dddddd; }

@media (max-width: 480px) {
  .table-responsive.table-break-small td {
    border: 0;
    padding-bottom: 0; } }

@media (min-width: 480px) {
  .table-responsive.table-break-small td::before {
    display: none; } }

.table-responsive.table-break-small td:last-child {
  padding-bottom: 14px; }

@media (min-width: 480px) {
  .table-responsive.table-break-small th,
  .table-responsive.table-break-small td {
    display: table-cell; } }

/**
 *  TABS
 *
 *  Markup:
 *  -------
 *
 *  <div class="c-tabs">
 *    <a href="link" title="#" data-targetclass="js-tab1" class="c-tab js-tab">[...]</a>
 *    <a href="link" title="#" data-targetclass="js-tab2" class="c-tab js-tab active">[...]</a>
 *  </div>
 *  <div class="js-tab1 c-tab-content">
 *    [...]
 *  </div>
 *  <div class="js-tab2 c-tab-content selected">
 *    [...]
 *  </div>
 *
 */
.tabs {
  border-bottom: 1px solid #dddddd;
  margin-bottom: 30px;
  text-align: center; }
  .tabs .tab {
    border-bottom: 3px solid transparent;
    color: #969da6;
    display: inline-block;
    line-height: 50px;
    margin: 0;
    margin-right: 30px;
    min-width: 70px;
    position: relative; }
    .tabs .tab:hover {
      color: #1595d1;
      text-decoration: none; }
    .tabs .tab.active {
      border-bottom: 3px solid #1595d1;
      color: #1595d1; }

.tab-content {
  display: none; }
  .tab-content.selected {
    display: block; }

/**
 *  TYPOGRAPHY
 */
body {
  font: 300 20px/1.3em "bilo", "Helvetica", "Arial", sans-serif;
  color: #0a0a0a; }
  @media (min-width: 768px) {
    body {
      font-size: 17px; } }
  @media (min-width: 992px) {
    body {
      font-size: 20px; } }

img {
  font-size: 12px;
  line-height: 1.3em; }

p {
  line-height: 1.3em;
  font-weight: 300; }

a {
  color: #e43879;
  text-decoration: none; }
  a:hover {
    color: rgba(228, 56, 121, 0.8); }
  a:focus {
    color: #e43879; }

.text-huge, .text-big, .text-medium {
  line-height: 1.3em;
  margin-bottom: 1em;
  margin-top: 1.5em; }
  .text-huge:first-child, .text-big:first-child, .text-medium:first-child {
    margin-top: .5em; }

.text-huge {
  font-family: "bilo", "Helvetica", "Arial", sans-serif;
  font-size: 30px;
  font-weight: 700;
  text-transform: none; }
  @media (min-width: 992px) {
    .text-huge {
      font-size: 40px; } }
  @media (min-width: 1280px) {
    .text-huge {
      font-size: 48px; } }

.text-big {
  font-family: "bilo", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: none; }
  @media (min-width: 992px) {
    .text-big {
      font-size: 28px; } }

.text-medium {
  font-family: "bilo", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-weight: 700;
  text-transform: none; }

.text-small, label.label {
  font-size: 14px; }
  @media (min-width: 768px) {
    .text-small, label.label {
      font-size: 16px; } }

.text-body {
  font-size: 20px;
  line-height: 1.5em; }
  @media (min-width: 992px) {
    .text-body {
      font-size: 20px; } }

.text-primary {
  color: #1595d1; }

.text-dark {
  color: #18537a; }

.text-secondary {
  color: #e43879; }

.text-white {
  color: #ffffff; }

.text-success {
  color: #4caf50; }

.text-info {
  color: #5bc0de; }

.text-warning {
  color: #f0ad4e; }

.text-error {
  color: #e74c3c; }

.text-gray, label.label, .table-responsive td::before {
  color: #838383; }

.text-gray-light {
  color: #edf3f6; }

.text-rating1 {
  color: #dd2c00; }

.text-rating2 {
  color: #ff5722; }

.text-rating3 {
  color: #ff9800; }

.text-rating4 {
  color: #ffc107; }

.text-rating5 {
  color: #f5dc00; }

.text-rating6 {
  color: #cddc39; }

.text-rating7 {
  color: #8bc34a; }

.text-rating8 {
  color: #4caf50; }

.text-rating9 {
  color: #43a047; }

.text-rating10 {
  color: #388e3c; }

.text-white {
  color: #ffffff; }

.text-gray, label.label, .table-responsive td::before,
a.text-gray,
button.text-gray {
  color: #969da6; }

.text-light {
  font-weight: 300; }

.text-normal {
  font-weight: 400; }

.text-line-through {
  text-decoration: line-through; }

.text-italic {
  font-style: italic; }

.text-uppercase {
  text-transform: uppercase; }

.text-transform-none {
  text-transform: none; }

.text-center {
  text-align: center; }

.text-lato {
  font-family: 'Lato', sans-serif; }

.text-with-subtitle {
  margin-bottom: 0 !important; }
  .text-with-subtitle + .text-huge,
  .text-with-subtitle + .text-big,
  .text-with-subtitle + .text-medium,
  .text-with-subtitle + .text-small,
  .text-with-subtitle + label.label {
    margin-top: .5em; }

h1,
h2,
h3,
h4 {
  font-weight: 700;
  line-height: 1;
  margin-bottom: 20px;
  font-family: "futura-pt", "Helvetica", "Arial", sans-serif; }

h1 {
  font-size: 2.0736rem; }
  h1 {
    font-size: 27.648px; }
    @media screen and (min-width: 480px) {
      h1 {
        font-size: calc(27.648px + 13.824 * ((100vw - 480px) / 800)); } }
    @media screen and (min-width: 1280px) {
      h1 {
        font-size: 41.472px; } }

h2 {
  font-size: 1.728rem; }
  h2 {
    font-size: 27.648px; }
    @media screen and (min-width: 480px) {
      h2 {
        font-size: calc(27.648px + 6.912 * ((100vw - 480px) / 800)); } }
    @media screen and (min-width: 1280px) {
      h2 {
        font-size: 34.56px; } }

h3 {
  font-size: 1.44rem; }
  h3 {
    font-size: 23.04px; }
    @media screen and (min-width: 480px) {
      h3 {
        font-size: calc(23.04px + 5.76 * ((100vw - 480px) / 800)); } }
    @media screen and (min-width: 1280px) {
      h3 {
        font-size: 28.8px; } }

h4 {
  font-size: 1.2rem; }
  h4 {
    font-size: 19.2px; }
    @media screen and (min-width: 480px) {
      h4 {
        font-size: calc(19.2px + 4.8 * ((100vw - 480px) / 800)); } }
    @media screen and (min-width: 1280px) {
      h4 {
        font-size: 24px; } }

.container-fluid,
.container {
  margin-right: auto;
  margin-left: auto; }

.container-fluid {
  padding-right: 0;
  padding-left: 0;
  max-width: 1200px; }

@media only screen and (max-width: 1200px) {
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px; } }

.row {
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem; }

.row.reverse {
  flex-direction: row-reverse; }

.col.reverse {
  flex-direction: column-reverse; }

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12 {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem; }

.col-xs {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%; }

.col-xs-1 {
  flex-basis: 8.333%;
  max-width: 8.333%; }

.col-xs-2 {
  flex-basis: 16.667%;
  max-width: 16.667%; }

.col-xs-3 {
  flex-basis: 25%;
  max-width: 25%; }

.col-xs-4 {
  flex-basis: 33.333%;
  max-width: 33.333%; }

.col-xs-5 {
  flex-basis: 41.667%;
  max-width: 41.667%; }

.col-xs-6 {
  flex-basis: 50%;
  max-width: 50%; }

.col-xs-7 {
  flex-basis: 58.333%;
  max-width: 58.333%; }

.col-xs-8 {
  flex-basis: 66.667%;
  max-width: 66.667%; }

.col-xs-9 {
  flex-basis: 75%;
  max-width: 75%; }

.col-xs-10 {
  flex-basis: 83.333%;
  max-width: 83.333%; }

.col-xs-11 {
  flex-basis: 91.667%;
  max-width: 91.667%; }

.col-xs-12 {
  flex-basis: 100%;
  max-width: 100%; }

.col-xs-offset-1 {
  margin-left: 8.333%; }

.col-xs-offset-2 {
  margin-left: 16.667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.333%; }

.col-xs-offset-5 {
  margin-left: 41.667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.333%; }

.col-xs-offset-8 {
  margin-left: 66.667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.333%; }

.col-xs-offset-11 {
  margin-left: 91.667%; }

.start-xs {
  justify-content: flex-start;
  text-align: start; }

.center-xs {
  justify-content: center;
  text-align: center; }

.end-xs {
  justify-content: flex-end;
  text-align: end; }

.top-xs {
  align-items: flex-start; }

.middle-xs {
  align-items: center; }

.bottom-xs {
  align-items: flex-end; }

.around-xs {
  justify-content: space-around; }

.between-xs {
  justify-content: space-between; }

.first-xs {
  order: -1; }

.last-xs {
  order: 1; }

@media only screen and (min-width: 48em) {
  .container {
    width: 49rem; }
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-offset-1,
  .col-sm-offset-2,
  .col-sm-offset-3,
  .col-sm-offset-4,
  .col-sm-offset-5,
  .col-sm-offset-6,
  .col-sm-offset-7,
  .col-sm-offset-8,
  .col-sm-offset-9,
  .col-sm-offset-10,
  .col-sm-offset-11,
  .col-sm-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-sm {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-sm-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-sm-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-sm-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-sm-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-sm-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-sm-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-sm-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-sm-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-sm-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-sm-offset-1 {
    margin-left: 8.333%; }
  .col-sm-offset-2 {
    margin-left: 16.667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.333%; }
  .col-sm-offset-5 {
    margin-left: 41.667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.333%; }
  .col-sm-offset-8 {
    margin-left: 66.667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.333%; }
  .col-sm-offset-11 {
    margin-left: 91.667%; }
  .start-sm {
    justify-content: flex-start;
    text-align: start; }
  .center-sm {
    justify-content: center;
    text-align: center; }
  .end-sm {
    justify-content: flex-end;
    text-align: end; }
  .top-sm {
    align-items: flex-start; }
  .middle-sm {
    align-items: center; }
  .bottom-sm {
    align-items: flex-end; }
  .around-sm {
    justify-content: space-around; }
  .between-sm {
    justify-content: space-between; }
  .first-sm {
    order: -1; }
  .last-sm {
    order: 1; } }

@media only screen and (min-width: 64em) {
  .container {
    width: 65rem; }
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-offset-1,
  .col-md-offset-2,
  .col-md-offset-3,
  .col-md-offset-4,
  .col-md-offset-5,
  .col-md-offset-6,
  .col-md-offset-7,
  .col-md-offset-8,
  .col-md-offset-9,
  .col-md-offset-10,
  .col-md-offset-11,
  .col-md-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-md {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-md-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-md-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-md-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-md-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-md-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-md-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-md-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-md-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-md-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-md-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-md-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-md-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-md-offset-1 {
    margin-left: 8.333%; }
  .col-md-offset-2 {
    margin-left: 16.667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.333%; }
  .col-md-offset-5 {
    margin-left: 41.667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.333%; }
  .col-md-offset-8 {
    margin-left: 66.667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.333%; }
  .col-md-offset-11 {
    margin-left: 91.667%; }
  .start-md {
    justify-content: flex-start;
    text-align: start; }
  .center-md {
    justify-content: center;
    text-align: center; }
  .end-md {
    justify-content: flex-end;
    text-align: end; }
  .top-md {
    align-items: flex-start; }
  .middle-md {
    align-items: center; }
  .bottom-md {
    align-items: flex-end; }
  .around-md {
    justify-content: space-around; }
  .between-md {
    justify-content: space-between; }
  .first-md {
    order: -1; }
  .last-md {
    order: 1; } }

@media only screen and (min-width: 75em) {
  .container {
    width: 76rem; }
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-offset-1,
  .col-lg-offset-2,
  .col-lg-offset-3,
  .col-lg-offset-4,
  .col-lg-offset-5,
  .col-lg-offset-6,
  .col-lg-offset-7,
  .col-lg-offset-8,
  .col-lg-offset-9,
  .col-lg-offset-10,
  .col-lg-offset-11,
  .col-lg-offset-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .col-lg {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%; }
  .col-lg-1 {
    flex-basis: 8.333%;
    max-width: 8.333%; }
  .col-lg-2 {
    flex-basis: 16.667%;
    max-width: 16.667%; }
  .col-lg-3 {
    flex-basis: 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex-basis: 33.333%;
    max-width: 33.333%; }
  .col-lg-5 {
    flex-basis: 41.667%;
    max-width: 41.667%; }
  .col-lg-6 {
    flex-basis: 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex-basis: 58.333%;
    max-width: 58.333%; }
  .col-lg-8 {
    flex-basis: 66.667%;
    max-width: 66.667%; }
  .col-lg-9 {
    flex-basis: 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex-basis: 83.333%;
    max-width: 83.333%; }
  .col-lg-11 {
    flex-basis: 91.667%;
    max-width: 91.667%; }
  .col-lg-12 {
    flex-basis: 100%;
    max-width: 100%; }
  .col-lg-offset-1 {
    margin-left: 8.333%; }
  .col-lg-offset-2 {
    margin-left: 16.667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.333%; }
  .col-lg-offset-5 {
    margin-left: 41.667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.333%; }
  .col-lg-offset-8 {
    margin-left: 66.667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.333%; }
  .col-lg-offset-11 {
    margin-left: 91.667%; }
  .start-lg {
    justify-content: flex-start;
    text-align: start; }
  .center-lg {
    justify-content: center;
    text-align: center; }
  .end-lg {
    justify-content: flex-end;
    text-align: end; }
  .top-lg {
    align-items: flex-start; }
  .middle-lg {
    align-items: center; }
  .bottom-lg {
    align-items: flex-end; }
  .around-lg {
    justify-content: space-around; }
  .between-lg {
    justify-content: space-between; }
  .first-lg {
    order: -1; }
  .last-lg {
    order: 1; } }

/**
 * EA Custom files
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff "/assets/img/ajax-loader.gif" center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/assets/fonts/slick.eot");
  src: url("/assets/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/assets/fonts/slick.woff") format("woff"), url("/assets/fonts/slick.ttf") format("truetype"), url("/assets/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 1; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: #1595d1;
    opacity: 0.85;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -20px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -20px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -20px; }
  [dir="rtl"] .slick-next {
    left: -20px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -35px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 15px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: #e43879;
      opacity: 0.85; }

.body-container {
  height: 100vh; }

.vert-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.phone-sss {
  font-size: 35px;
  font-weight: 800;
  margin: 30px 0;
  display: inline-block;
  color: #fff;
  text-decoration: none; }

.btn-home {
  display: inline-block;
  margin: 15px 0;
  border: 3px solid white;
  font-size: 20px;
  border-radius: 3px;
  color: #fff;
  padding: 12px 20px;
  text-transform: uppercase;
  transition: all .2s; }
  .btn-home:hover {
    color: #fff;
    background: #169fdf; }

.no-js-card-img {
  width: 100%; }

.row {
  display: flex; }

.valign {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.subscription {
  background: #d8e6ec;
  padding: 80px 0; }
  .subscription h2 {
    color: #1595d1;
    margin-bottom: 50px; }
  .subscription .input {
    width: 100%; }
  .subscription .button {
    margin-top: 25px; }
  @media (max-width: 767px) {
    .subscription {
      padding: 50px 0; }
      .subscription h2 {
        margin-bottom: 30px; } }

.form .input {
  width: 100%; }

body.show-menu {
  overflow-y: hidden; }

.main-header {
  padding: 15px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  transition: all .2s; }
  .main-header .logo {
    width: 170px;
    display: block;
    position: relative;
    max-width: 100%;
    padding: 2px 0 0;
    line-height: 1; }
    .main-header .logo img {
      position: absolute;
      left: 0;
      transition: all .2s; }
  .main-header .nav-toggle {
    border: none;
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 4px 10px;
    margin-top: 18px;
    margin-bottom: 18px;
    background-color: transparent;
    background-image: none; }
    .main-header .nav-toggle:focus {
      outline: none; }
    .main-header .nav-toggle .icon-bar {
      display: block;
      width: 24px;
      height: 3px;
      border-bottom: 2px solid #fff;
      margin-top: 5px; }
  .main-header .nav {
    list-style: none;
    margin: 0; }
    .main-header .nav li {
      display: inline-block;
      margin: 0;
      position: relative; }
      .main-header .nav li a {
        display: block;
        padding: 26px 16px;
        text-align: center;
        font-size: 17px;
        color: #fff;
        transition: all .2s; }
        .main-header .nav li a:hover {
          color: #e43879; }
        @media (max-width: 991px) {
          .main-header .nav li a {
            font-size: 16px;
            padding: 26px 10px; } }
      .main-header .nav li.fb a {
        padding: 0; }
        .main-header .nav li.fb a svg {
          fill: currentColor; }
      .main-header .nav li ul.dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        display: none;
        float: left;
        min-width: 240px;
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 14px;
        text-align: left;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 16px 12px rgba(0, 0, 0, 0.175);
        background-clip: padding-box; }
        .main-header .nav li ul.dropdown-menu li {
          width: 100%; }
          .main-header .nav li ul.dropdown-menu li a {
            text-align: left;
            padding: 8px 16px;
            color: #424242; }
            .main-header .nav li ul.dropdown-menu li a:hover {
              color: #e43879; }
          .main-header .nav li ul.dropdown-menu li.dropdown-parent-mob {
            display: none; }
    @media (min-width: 993px) {
      .main-header .nav li:hover ul.dropdown-menu {
        display: block; } }
    @media (max-width: 991px) {
      .main-header .nav {
        position: fixed;
        z-index: 1000;
        height: 100vh;
        padding: 15vh 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        width: 100vw;
        right: -100vw;
        top: 0;
        bottom: 0;
        opacity: 0;
        background-color: #fff;
        transition: opacity .1s linear,right 0s linear .2s; }
        .main-header .nav .nav-close {
          height: 56px;
          width: 56px;
          position: absolute;
          top: 16px;
          right: 20px;
          border-radius: 100%;
          cursor: pointer;
          z-index: 1;
          border: none; }
          .main-header .nav .nav-close:before, .main-header .nav .nav-close:after {
            content: "";
            height: 21px;
            width: 2px;
            background-color: #0f0f0f;
            display: block;
            position: absolute;
            left: 26px;
            top: 16px; }
          .main-header .nav .nav-close:before {
            transform: rotate(-45deg); }
          .main-header .nav .nav-close:after {
            transform: rotate(45deg); }
        .main-header .nav .item {
          display: block;
          width: 100%; }
          .main-header .nav .item a {
            color: #000;
            font-size: 26px; }
            .main-header .nav .item a .dropdown-icon {
              display: inline-block;
              width: 12px;
              height: 12px;
              border-right: 2px solid #0f0f0f;
              border-bottom: 2px solid #0f0f0f;
              transform: translateY(-3px) rotate(45deg);
              margin-left: 12px;
              margin-right: -12px; }
          .main-header .nav .item ul.dropdown-menu {
            display: none;
            position: relative;
            width: 100%;
            background: #ebf5fb;
            padding: 20px 0;
            box-shadow: none;
            margin: 0px 0 20px 0; }
            .main-header .nav .item ul.dropdown-menu li a {
              text-align: center;
              padding: 18px 0; }
            .main-header .nav .item ul.dropdown-menu li.dropdown-parent-mob {
              display: block; }
            .main-header .nav .item ul.dropdown-menu.show {
              display: block; }
        .main-header .nav.show {
          right: 0;
          opacity: 1; } }
  .main-header.fixed {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.12); }
    .main-header.fixed .nav li a {
      color: #1595d1; }
      .main-header.fixed .nav li a:hover {
        color: #144861; }
    .main-header.fixed .nav li ul.dropdown-menu li a {
      color: #1595d1; }
      .main-header.fixed .nav li ul.dropdown-menu li a:hover {
        color: #144861; }
    @media (max-width: 991px) {
      .main-header.fixed .nav-toggle .icon-bar {
        border-bottom: 2px solid #000; }
      .main-header.fixed .nav .item a {
        color: #000; } }
    .main-header.fixed .logo .white {
      opacity: 0; }

@media only screen and (max-width: 1200px) {
  .main-header .nav li a {
    font-size: 15px;
    padding: 26px 10px; } }

@media only screen and (max-width: 64em) {
  .container-header {
    width: 100%; } }

.resource-card {
  overflow: hidden;
  box-shadow: -1px -1px 15px -2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  position: relative; }
  .resource-card .card-image {
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transition: all .2s; }
    .resource-card .card-image img {
      height: 0;
      object-fit: cover;
      width: 100%;
      padding-bottom: 56.25%; }
      .resource-card .card-image img.lazyloaded {
        height: 100%;
        padding: 0; }
  .resource-card .title {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px 0; }
    .resource-card .title h4 {
      margin: 0;
      text-shadow: 0px 0px 15px #000;
      color: #fff; }
  .resource-card:hover .card-image {
    transform: scale(1.1); }

.card {
  margin-bottom: 80px; }
  .card .card-image {
    line-height: 0;
    position: relative;
    overflow: hidden;
    margin: 0 15px 30px 15px;
    box-shadow: -1px -1px 15px -2px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: all .2s; }
    .card .card-image img {
      height: 0;
      object-fit: cover;
      width: 100%;
      padding-bottom: 56.25%;
      transition: transform .3s; }
      .card .card-image img.lazyloaded {
        height: 100%;
        padding: 0; }
    .card .card-image .play-icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      z-index: 2; }
      .card .card-image .play-icon svg {
        stroke: #000;
        stroke-width: 1px; }
    .card .card-image:hover img {
      transform: scale(1.05); }
  .card a.card-title {
    display: inline-block;
    margin-bottom: 30px; }
    .card a.card-title h4 {
      display: inline;
      line-height: 1.2;
      border-bottom: 2px solid rgba(255, 255, 255, 0);
      transition: all .2s; }
    .card a.card-title:hover h4 {
      border-bottom: 2px solid rgba(228, 56, 121, 0.8); }

body.home {
  background: #1595d1; }
  body.home .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    max-height: 700px;
    height: 100vh;
    width: 100%;
    min-height: 570px;
    background: #1595d1;
    transition: height .2s; }
    body.home .hero-image img {
      height: 0;
      object-fit: cover;
      width: 100%;
      padding-bottom: 56.25%; }
      body.home .hero-image img.lazyloaded {
        height: 100%;
        padding: 0; }
    body.home .hero-image .hero-overlay {
      position: absolute;
      opacity: 0.55;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #048ccc; }
  body.home .hero-container {
    position: relative;
    padding-top: 200px;
    height: 100vh;
    min-height: calc(570px);
    max-height: 700px; }
    body.home .hero-container h1 {
      font-size: 50px;
      line-height: 1.1;
      color: #fff;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.45); }
    body.home .hero-container h4 {
      font-family: "bilo", "Helvetica", "Arial", sans-serif;
      font-weight: 300;
      line-height: 1.3;
      color: #fff;
      margin-bottom: 40px; }
    body.home .hero-container .button:first-of-type {
      margin-left: 0; }
  body.home .home-container {
    background: #fff; }
    body.home .home-container .home-row {
      padding: 60px 0; }
      body.home .home-container .home-row h2 {
        border-bottom: 4px solid #e43879;
        display: inline-block;
        padding-bottom: 5px; }
      body.home .home-container .home-row .home-image {
        overflow: hidden;
        line-height: 0;
        box-shadow: -1px -1px 15px -2px rgba(0, 0, 0, 0.3);
        border-radius: 8px; }
        body.home .home-container .home-row .home-image img {
          height: 0;
          object-fit: cover;
          width: 100%;
          padding-bottom: 56.25%; }
          body.home .home-container .home-row .home-image img.lazyloaded {
            height: 100%;
            padding: 0; }
      body.home .home-container .home-row .slider-container {
        width: 100%;
        overflow: visible;
        display: block; }
        body.home .home-container .home-row .slider-container .slick-slide {
          margin: 0 15px; }
        body.home .home-container .home-row .slider-container .slick-prev {
          left: -50px; }
        body.home .home-container .home-row .slider-container .slick-next {
          right: -50px; }
        body.home .home-container .home-row .slider-container .slick-prev, body.home .home-container .home-row .slider-container .slick-next {
          height: 35px;
          width: 35px; }
          body.home .home-container .home-row .slider-container .slick-prev:before, body.home .home-container .home-row .slider-container .slick-next:before {
            font-size: 35px; }
        body.home .home-container .home-row .slider-container .resource-card {
          position: relative;
          overflow: hidden;
          height: 170px;
          width: 100%;
          margin: 0 7px;
          border-radius: 5px;
          background: #fff;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          transition: all 0.25s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s; }
          body.home .home-container .home-row .slider-container .resource-card:hover {
            box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2); }
            body.home .home-container .home-row .slider-container .resource-card:hover .image img, body.home .home-container .home-row .slider-container .resource-card:hover .image .no-js-card-img {
              transform: scale(1.04); }
          body.home .home-container .home-row .slider-container .resource-card .image {
            overflow: hidden; }
            body.home .home-container .home-row .slider-container .resource-card .image img {
              max-height: 200px;
              height: 100%;
              object-fit: cover;
              width: 100%;
              transition: all .25s ease-out; }
          body.home .home-container .home-row .slider-container .resource-card .card-inner {
            padding: 30px; }
            body.home .home-container .home-row .slider-container .resource-card .card-inner h4 {
              margin-bottom: 5px;
              line-height: 1.3;
              height: 2.6em;
              overflow: hidden; }
              body.home .home-container .home-row .slider-container .resource-card .card-inner h4 a {
                color: #000; }
            body.home .home-container .home-row .slider-container .resource-card .card-inner p {
              cursor: text;
              min-height: calc(1.8em * 3); }
            body.home .home-container .home-row .slider-container .resource-card .card-inner .btn-border {
              margin: 0 0 30px 0;
              float: right; }
          body.home .home-container .home-row .slider-container .resource-card .card-link-mob {
            display: none; }
            @media (max-width: 767px) {
              body.home .home-container .home-row .slider-container .resource-card .card-link-mob {
                display: block;
                height: 100%;
                width: 100%;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0; } }
      body.home .home-container .home-row h1 {
        margin-bottom: 50px; }
  body.home .top {
    background: #e43879;
    color: #1595d1;
    padding: 30px; }
    body.home .top h1, body.home .top h2 {
      font-family: "futura-pt", "Helvetica", "Arial", sans-serif; }
  body.home .blocks {
    margin: 30px 0; }
    body.home .blocks .block-row {
      margin: 0;
      padding: 40px 0; }
      body.home .blocks .block-row .row {
        flex: 1; }
      body.home .blocks .block-row .block-text {
        padding: 30px 60px; }
      body.home .blocks .block-row .block-img img {
        line-height: 0; }
      body.home .blocks .block-row:first-child {
        padding-top: 0; }
      body.home .blocks .block-row:nth-child(even) {
        background: #e43879;
        color: #1595d1; }
  body.home .bottom {
    margin-top: 30px;
    background: #e43879;
    color: #1595d1;
    padding: 30px; }
    body.home .bottom h2 {
      text-transform: uppercase; }
    body.home .bottom img {
      max-width: 200px; }
  @media (max-width: 991px) {
    body.home .home-container .home-row {
      padding: 0 0 60px 0; }
      body.home .home-container .home-row .home-image {
        margin-bottom: 30px; }
      body.home .home-container .home-row:first-of-type {
        padding-top: 60px; } }
  @media (max-width: 767px) {
    body.home .hero-container {
      text-align: center; }
    body.home .home-container {
      text-align: center; }
      body.home .home-container .home-row .slider-container {
        margin-top: 30px;
        overflow: hidden; } }

body.page {
  background: #fff; }
  body.page .title-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 30vh;
    width: 100%;
    min-height: 400px;
    background: #1595d1;
    transition: height .2s; }
    body.page .title-image img {
      height: 0;
      object-fit: cover;
      width: 100%;
      padding-bottom: 56.25%; }
      body.page .title-image img.lazyloaded {
        height: 100%;
        padding: 0; }
    body.page .title-image .hero-overlay {
      position: absolute;
      opacity: 0.55;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #048ccc; }
  body.page .title-container {
    position: relative;
    padding-top: 200px;
    height: 30vh;
    min-height: 400px;
    text-align: center;
    margin-bottom: 40px; }
    body.page .title-container h1 {
      font-size: 50px;
      line-height: 1.1;
      color: #fff;
      text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.45); }
    body.page .title-container.no-featured-image {
      height: auto;
      padding: calc(100px + 70px) 0 70px;
      min-height: 0;
      background: #ebf5fb; }
      body.page .title-container.no-featured-image h1 {
        color: #0a0a0a;
        padding-bottom: 7px;
        border-bottom: 4px solid #e82772;
        text-shadow: none;
        display: inline-block; }
  body.page .page-content {
    margin: 30p 0; }
    body.page .page-content h1 {
      margin-bottom: 10px; }
    body.page .page-content .heading {
      text-align: center; }
      body.page .page-content .heading h1 {
        margin: 40px 0; }
    body.page .page-content .text {
      margin: 0px 0px 40px 20px; }
    body.page .page-content h4 {
      font-weight: 100;
      font-size: 20px;
      line-height: 1.6;
      margin: 0 0 30px 0; }
    body.page .page-content a:not(.button) {
      border-bottom: 1px solid #000;
      padding-bottom: 1px; }
      body.page .page-content a:not(.button):hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0); }
    body.page .page-content p {
      line-height: 1.8em;
      margin-bottom: 1em;
      font-weight: 300;
      font-size: 20px; }
    body.page .page-content img {
      border-radius: 3px; }
    body.page .page-content .block-image.book {
      text-align: center;
      margin: 0 10px; }
      body.page .page-content .block-image.book img {
        max-width: 150px; }
      body.page .page-content .block-image.book p {
        line-height: 1.3em;
        margin-top: 10px;
        margin-bottom: 30px; }
    body.page .page-content .embed-wrapper {
      position: relative;
      padding-bottom: 56.25%; }
      body.page .page-content .embed-wrapper iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    body.page .page-content .button-container {
      text-align: center; }
    body.page .page-content .testimonial-quote {
      position: relative;
      margin: 40px 0 140px 0; }
      body.page .page-content .testimonial-quote .quote-image img {
        margin-top: 60px;
        box-shadow: 0 0 20px -3px #6b6b6b;
        display: block;
        max-width: 100%;
        border-radius: 14px; }
      body.page .page-content .testimonial-quote .quote-container {
        padding-left: 40px; }
      body.page .page-content .testimonial-quote blockquote {
        position: relative; }
        body.page .page-content .testimonial-quote blockquote p {
          line-height: 1.5;
          font-size: 23px; }
          body.page .page-content .testimonial-quote blockquote p:first-child:before {
            content: '\201C';
            color: #fff;
            background: #1595d1;
            font-size: 42px;
            line-height: 1;
            font-weight: 700;
            text-shadow: none;
            padding: 5px 8px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: block;
            margin-bottom: 20px; }
      body.page .page-content .testimonial-quote cite {
        color: gray;
        display: block;
        font-size: .8em; }
        body.page .page-content .testimonial-quote cite span {
          color: #5e5e5e;
          font-weight: 700;
          font-style: normal;
          letter-spacing: 1px;
          text-transform: uppercase;
          text-shadow: 0 1px white; }
      body.page .page-content .testimonial-quote.right .quote-container {
        padding-left: 0;
        padding-right: 40px; }
      body.page .page-content .testimonial-quote.right cite {
        text-align: right; }
    body.page .page-content .well {
      position: relative;
      padding: 50px 0;
      margin: 10px 0 40px 0;
      text-align: center; }
      body.page .page-content .well:before {
        content: " ";
        background: #ebf5fb;
        position: absolute;
        left: -100%;
        top: 0;
        width: 300%;
        height: 100%;
        z-index: -1;
        transition: all 1.5s ease-out;
        transition-delay: 0;
        opacity: 1; }
    body.page .page-content .spacer {
      height: 50px; }
  body.page .index-cards {
    margin-top: 40px; }
    body.page .index-cards h3 {
      border-bottom: 4px solid #e43879;
      display: inline-block;
      padding-bottom: 5px;
      margin-bottom: 30px; }

.pull-right {
  float: right; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.main-footer {
  padding: 50px 0;
  margin: 40px 0 0 0;
  text-align: center;
  color: #fff;
  background: #1595d1; }
  .main-footer img {
    width: 30px;
    margin-right: 7px;
    line-height: 1; }
  .main-footer p {
    margin-bottom: 0;
    display: inline-block;
    padding: 5px 30px; }
  .main-footer .timcoysh a {
    color: #fff; }
    .main-footer .timcoysh a:hover {
      text-decoration: underline; }
  .main-footer .timcoysh svg {
    display: inline-block;
    height: 20px;
    position: relative;
    top: 5px;
    padding: 0 5px; }
